import Box from "@mui/material/Box"
import React from "react"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import Section1 from "../components/ContactUS/Section1"
import Section2 from "../components/ContactUS/Section2"
import { Helmet } from "react-helmet-async"

export const ContactUs = () => {
  return (
    <Box>
      <Helmet>
        <title>ARC Digital | Contact Us</title>
      </Helmet>

      <Navbar page="contact" textColor="#1A1F2B" />

      {/**body */}
      <Section1 />
      <Section2 />
      <Footer />
    </Box>
  )
}

export default ContactUs
