import { useState } from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import { useNavigate } from "react-router-dom"
import axios from "../../api/axios"
import * as yup from "yup"
import { useFormik } from "formik"
import Swal from "sweetalert2"
import About from "./About"
import Details from "./Details"
import { HashLink } from "react-router-hash-link"

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const aboutSchema = yup.object({
  first_name: yup
    .string("Enter your first name")
    .required("This field is required")
    .min(3, "First name must be at least 3 characters"),
  last_name: yup
    .string("Enter your last name")
    .required("This field is required")
    .min(3, "Last name must be at least 3 characters"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("This field is required"),
  github: yup
    .string("Enter your github account")
    .required("This field is required"),
  experience: yup
    .string("Enter your programming experience")
    .required("This field is required"),
  commitment_level: yup
    .string("Enter your commitment level")
    .required("This field is required"),
  course: yup.string("Select a course").required("This field is required"),
  phone: yup
    .string("Enter your phone number")
    .matches(phoneRegExp, "Enter a valid phone number")
    .required("This field is required"),
  stacks: yup
    .array(yup.string())
    .min(1, "Enter your tech stacks")
    .required("This field is required"),
  expectations_post_training: yup
    .string("Enter your post training expectations")
    .required("This field is required"),
})

const detailsSchema = yup.object({
  about_yourself: yup
    .string("Enter your background and experience")
    .required("This field is required"),
  motivation: yup
    .string("Enter your motivation for applying for this programme")
    .required("This field is required"),
  staying_motivated: yup
    .string("Enter your means of staying motivated")
    .required("This field is required"),
  expectations_during_training: yup
    .string("Enter your expectations for the training")
    .required("This field is required"),
  computer_access: yup
    .string("Enter your computer and internet connectivity access")
    .required("This field is required"),
  hear_about_programme: yup
    .string("Enter how you heard about the programme")
    .required("This field is required"),
  funding: yup
    .string("Enter how you are funding this programme")
    .required("This field is required"),
  payment_period: yup
    .string("Enter the time you will commit")
    .required("This field is required"),
  payment_option: yup
    .string("Enter your preferred payment option")
    .required("This field is required"),
})

const Registration = () => {
  const [step, setStep] = useState(1)
  const navigate = useNavigate()
  const [checked, setChecked] = useState(false)

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      github: "",
      experience: "",
      commitment_level: "",
      course: "",
      phone: "",
      stacks: [],
      expectations_post_training: "",
      about_yourself: "",
      motivation: "",
      staying_motivated: "",
      expectations_during_training: "",
      computer_access: "",
      hear_about_programme: "",
      funding: "",
      payment_period: "",
      payment_option: "",
      receive_communication: checked,
    },
    validationSchema: step === 2 ? detailsSchema : aboutSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      if (step === 1) {
        setStep(2)
      }

      if (step === 2) {
        const response = await axios
          .post("users", {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email.toLowerCase(),
            github: values.github,
            experience: values.experience,
            commitment_level: values.commitment_level,
            course: values.course,
            phone: values.phone,
            stacks: values.stacks,
            expectations_post_training: values.expectations_post_training,
            about_yourself: values.about_yourself,
            motivation: values.motivation,
            staying_motivated: values.staying_motivated,
            expectations_during_training: values.expectations_during_training,
            computer_access: values.computer_access,
            hear_about_programme: values.hear_about_programme,
            funding: values.funding,
            payment_period: values.payment_period,
            payment_option: values.payment_option,
            receive_communication: checked,
          })
          .catch(error => {
            // console.error(error.response)
            Swal.fire({
              toast: true,
              icon: "error",
              title: error.response.data.message,
              position: "top-right",
              timer: 5000,
              showConfirmButton: false,
              // width: "auto",
            })
          })
        if (response.status === 201) {
          Swal.fire({
            toast: true,
            // icon: "success",
            title: "You have been registered for this course successfully.",
            position: "top-right",
            timer: 5000,
            showConfirmButton: false,
            width: "auto",
          })
          resetForm()
          navigate(-1)
        }
      }
    },
  })

  return (
    <Box
      sx={{ backgroundColor: "#002E5F", minHeight: "100vh", cursor: "default" }}
    >
      <AppBar
        sx={{
          backgroundColor: "inherit",
          boxShadow: "0px 0px 0px 0px",
          padding: {
            xs: "0rem 1rem",
            sm: "0rem 3rem",
            md: "0rem 5rem",
          },
        }}
        position="static"
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            fontWeight="800"
            sx={{
              color: "#FFFFFF",
              cursor: "pointer",
            }}
            onClick={() => navigate("/")}
          >
            IVYARC
          </Typography>

          <Box sx={{ display: "flex" }}>
            <Typography
              variant="subtitle1"
              fontWeight="500"
              sx={{
                color: "#FFFFFF",
                marginRight: "1.25rem",
              }}
            >
              Help
            </Typography>
            <HashLink to="/#faq" style={{ textDecoration: "none" }}>
              <Typography
                variant="subtitle1"
                fontWeight="500"
                sx={{
                  color: "#FFFFFF",
                  cursor: "pointer",
                }}
              >
                FAQ
              </Typography>
            </HashLink>
          </Box>
        </Toolbar>
      </AppBar>

      <Box sx={{ py: "4rem" }}>
        <Typography
          sx={{
            textAlign: "center",
            color: "#FFFFFF",
            fontWeight: "600",
            typography: { xs: "h4", md: "h3" },
          }}
          gutterBottom
        >
          Registration Form
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ textAlign: "center", color: "#FFFFFF", fontWeight: "600" }}
          gutterBottom
        >
          NOTE: Please ensure to provide the following information
        </Typography>

        {step === 2 ? (
          <Details formik={formik} checked={checked} setChecked={setChecked} />
        ) : (
          <About formik={formik} />
        )}
      </Box>
    </Box>
  )
}

export default Registration
