import { createTheme, ThemeProvider } from "@mui/material/styles"
import IconButton from "@mui/material/IconButton"
import { useEffect, useState } from "react"
import { Outlet } from "react-router-dom"
import { FiChevronUp } from "react-icons/fi"
import SyllabusContextProvider from "../context/SyllabusContext"

const Layout = () => {
  const theme = createTheme({
    typography: {
      fontFamily: ["Manrope", "Work Sans", "sans-serif"].join(","),
      ts1: {
        fontWeight: "700",
        fontSize: "64px",
        lineHeight: "83.2px",
      },
      ts2: {
        fontWeight: "700",
        fontSize: "40px",
        lineHeight: "52px",
      },
      ts3: {
        fontWeight: "700",
        fontSize: "36px",
        lineHeight: "46.8px",
      },
      talent1: {
        fontWeight: "600",
        fontSize: "2.125rem",
        lineHeight: "1.235",
      },
      talent2: {
        fontWeight: "600",
        fontSize: "1.5rem",
        lineHeight: "1.334",
      },
    },
  })

  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    const showButton = () => {
      if (window.scrollY > 300) {
        setIsActive(true)
      } else {
        setIsActive(false)
      }
    }

    window.addEventListener("scroll", showButton)
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <SyllabusContextProvider>
        <main style={{ position: "relative", minHeight: "100vh" }}>
          <Outlet />
          {isActive ? (
            <IconButton
              sx={{
                position: "fixed",
                bottom: "1rem",
                right: "1rem",
                zIndex: 500,
                bgcolor: "#F0F4FD",
                boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1)",
              }}
              onClick={() =>
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                })
              }
            >
              <FiChevronUp color="black" />
            </IconButton>
          ) : null}
        </main>
      </SyllabusContextProvider>
    </ThemeProvider>
  )
}

export default Layout
