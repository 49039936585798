import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import TextField from "@mui/material/TextField"
import MenuItem from "@mui/material/MenuItem"
import IconButton from "@mui/material/IconButton"
import { styled } from "@mui/material/styles"
import { courses } from "../utils/data"
import axios from "../api/axios"
import * as yup from "yup"
import { useFormik } from "formik"
import Swal from "sweetalert2"
import { FiX } from "react-icons/fi"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "75%", sm: 400 },
  bgcolor: "background.paper",
  border: "0px",
  boxShadow: 24,
  p: 4,
  outline: "none",
}

const StyledTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    color: "#000000",
  },
  "& .MuiSvgIcon-root": {
    color: "#000000",
  },
  "& label": {
    color: "#000000",
  },
  "&:hover label": {
    fontWeight: 700,
  },
  "& label.Mui-focused": {
    color: "#000000",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#000000",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#000000",
    },
    "&:hover fieldset": {
      borderColor: "#000000",
      borderWidth: 2,
    },
    "&.Mui-focused fieldset": {
      borderColor: "#000000",
    },
  },
})

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const validationSchema = yup.object({
  first_name: yup
    .string("Enter your first name")
    .required("This field is required"),
  last_name: yup
    .string("Enter your last name")
    .required("This field is required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("This field is required"),
  phone: yup
    .string("Enter your phone number")
    .matches(phoneRegExp, "Enter a valid phone number")
    .required("This field is required"),
  course: yup.string("Select a course").required("This field is required"),
})

const SelpModal = ({ showModal, setShowModal, course }) => {
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      course: course,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      const response = await axios
        .post("reserve", {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email.toLowerCase(),
          phone: values.phone,
          course: values.course,
        })
        .catch(error => {
          // console.error(error.response.data.message)
          Swal.fire({
            toast: true,
            icon: "error",
            title: error.response.data.message,
            position: "top-right",
            timer: 5000,
            showConfirmButton: false,
            // width: "auto",
          })
        })
      if (response.status === 201) {
        setShowModal(false)
        resetForm()
        Swal.fire({
          toast: true,
          // icon: "success",
          title: "Your reservation has been made successfully.",
          position: "top-right",
          timer: 5000,
          showConfirmButton: false,
          width: "auto",
        })
      }
    },
  })

  return (
    <Box>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="modal-modal-title"
      >
        <Box sx={style}>
          <IconButton
            sx={{ position: "absolute", top: "0px", right: "0px" }}
            onClick={() => setShowModal(false)}
          >
            <FiX />
          </IconButton>
          <Box
            sx={{
              position: "relative",
              width: "100px",
              mx: "auto",
            }}
          >
            <img
              src={require("../assets/Customer.png")}
              alt=""
              width="100%"
              height="auto"
              loading="lazy"
            />
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center", mt: "1rem" }}
          >
            Make a Reservation
          </Typography>

          <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{
              width: { xs: "100%", sm: "80%" },
              mt: "1rem",
              mx: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/**first name */}
            <StyledTextField
              fullWidth
              size="small"
              variant="filled"
              id="first_name"
              label="First Name"
              value={formik.values.first_name}
              onChange={formik.handleChange("first_name")}
              error={
                formik.touched.first_name && Boolean(formik.errors.first_name)
              }
              helperText={formik.touched.first_name && formik.errors.first_name}
            />

            {/**last name */}
            <StyledTextField
              fullWidth
              size="small"
              variant="filled"
              id="last_name"
              label="Last Name"
              value={formik.values.last_name}
              onChange={formik.handleChange("last_name")}
              error={
                formik.touched.last_name && Boolean(formik.errors.last_name)
              }
              helperText={formik.touched.last_name && formik.errors.last_name}
              sx={{ mt: "1rem" }}
            />

            {/**email */}
            <StyledTextField
              fullWidth
              size="small"
              variant="filled"
              id="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange("email")}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              sx={{ mt: "1rem" }}
            />

            {/**phone number */}
            <StyledTextField
              fullWidth
              size="small"
              variant="filled"
              id="phone"
              label="Phone Number"
              value={formik.values.phone}
              onChange={formik.handleChange("phone")}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              sx={{ mt: "1rem" }}
            />

            {/**course */}
            <StyledTextField
              disabled={course ? true : false}
              fullWidth
              size="small"
              variant="filled"
              id="course"
              label="Select Course"
              value={formik.values.course}
              onChange={formik.handleChange("course")}
              error={formik.touched.course && Boolean(formik.errors.course)}
              helperText={formik.touched.course && formik.errors.course}
              select
              sx={{ mt: "1rem" }}
            >
              {courses.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </StyledTextField>

            <Button
              variant="contained"
              sx={{ backgroundColor: "#FF7801", mt: "1.5rem" }}
              fullWidth
              type="submit"
              onClick={e => {
                formik.handleSubmit(e)
              }}
            >
              Reserve a seat now
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default SelpModal
