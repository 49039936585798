import { Box, Button, Typography } from "@mui/material";
import { FiCopy } from "react-icons/fi";
import { BsFacebook, BsLinkedin, BsTwitter } from "react-icons/bs";
import React from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

export const Section2 = () => {
  const currentUrlLink = window.location.href;
  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(currentUrlLink)
      .then(() => {
        Swal.fire({
          toast: true,
          icon: "success",
          text: "Link copied to clipboard",
          position: "top",
          confirmButtonColor:"#1A76D2",
          timer: 5000,
        });
      })
      .catch((error) => {
        console.error("Failed to copy link", error);
      });
  };
  return (
    <Box
      sx={{
        padding: {
          xs: "1rem 1.5rem",
          sm: "1rem 3rem",
          md: "1rem 6rem 7rem 6rem",
        },
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{
          flexDirection: {
            xs: "column",
            sm: "row",
          },
        }}
      >
        <Box display="flex">
          <Box>
            <img
              src={require("../../assets/Abai.jpg")}
              width="70"
              height="auto"
              style={{ borderRadius: "50%" }}
              alt=""
              loading="lazy"
            />
          </Box>
          <Box padding="0.3rem 0rem 0rem 1rem">
            <Typography variant="body1" fontFamily="Inter" fontWeight="600">
              Abai Ayomikun
            </Typography>
            <Typography
              variant="body2"
              fontFamily="Inter"
              fontWeight="400"
              color="#002E5F"
            >
              Frontend Web-Developer, IVYARC.
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          paddingTop="0.3rem"
          sx={{
            width: {
              xs: "70%",
              sm: "38%",
              md: "33%",
              lg: "21%",
              xl: "19%",
            },
            marginTop: {
              xs: "1rem",
              sm: "0",
            },
          }}
        >
          <Box width="45%">
            <Box
              onClick={copyToClipboard}
            >
              <Button variant="contained" sx={{textTransform:"lowercase"}}>
                <FiCopy />
                Copy link
              </Button>
            </Box>
          </Box>
          <Link to="http://www.twitter.com/ivyarcafrica">
            <Box
              border="2px solid #D0D5DD"
              borderRadius="5px"
              width="60%"
              height="1.6rem"
              color="#D0D5DD"
              padding="0.3rem 0.3rem 0rem 0.2rem"
              sx={{
                ":hover": {
                  backgroundColor: "#D0D5DD",
                  color: "#00acee",
                },
              }}
            >
              <BsTwitter size={20} />
            </Box>
          </Link>
          <Link to="http://www.facebook.com/ivyarcafrica">
            <Box
              border="2px solid #D0D5DD"
              borderRadius="5px"
              width="65%"
              height="1.6rem"
              color="#D0D5DD"
              padding="0.3rem 0.2rem 0rem 0.2rem"
              sx={{
                ":hover": {
                  backgroundColor: "#D0D5DD",
                  color: "#3b5998",
                },
              }}
            >
              <BsFacebook size={20} />
            </Box>
          </Link>
          <Link to="http://www.linkedin.com/company/ivyarcafrica">
            <Box
              border="2px solid #D0D5DD"
              borderRadius="5px"
              width="60%"
              height="1.6rem"
              color="#D0D5DD"
              padding="0.3rem 0.3rem 0rem 0.2rem"
              sx={{
                ":hover": {
                  backgroundColor: "#D0D5DD",
                  color: "#0072b1",
                },
              }}
            >
              <BsLinkedin size={20} />
            </Box>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default Section2;
