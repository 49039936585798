import { Box } from "@mui/material"
import React from "react"
import { Helmet } from "react-helmet-async"
import Header from "../components/AboutUs/Header"
import Section1 from "../components/AboutUs/Section1"
import Section2 from "../components/AboutUs/Section2"
import Section3 from "../components/AboutUs/Section3"
import Footer from "../components/Footer"

export const AboutUs = () => {
  return (
    <Box sx={{ margin: "0px", padding: "0px", cursor: "default" }}>
      <Helmet>
        <title>ARC Digital | About Us</title>
      </Helmet>

      <Header />
      <Section1 />
      <Section2 />
      <Section3 />
      <Footer />
    </Box>
  )
}

export default AboutUs
