import { useState } from "react"
import Box from "@mui/material/Box"
import Section2 from "../components/Services/Section2"
import Section3 from "../components/Services/Section3"
import Footer from "../components/Footer"
import Section1 from "../components/Services/Section1"
import Header from "../components/Services/Header"
import SelpModal from "../components/SelpModal"
import { Helmet } from "react-helmet-async"

export const Services = () => {
  const [showModal, setShowModal] = useState(false)
  return (
    <Box>
      <Helmet>
        <title>ARC Digital | Services</title>
      </Helmet>

      <SelpModal showModal={showModal} setShowModal={setShowModal} />
      <Header />
      <Section1 />
      <Section2 setShowModal={setShowModal} />
      <Section3 />
      <Footer />
    </Box>
  )
}

export default Services
