import Box from "@mui/material/Box"
import { useState } from "react"
import { Helmet } from "react-helmet-async"
import Footer from "../../components/Footer"
import Navbar from "../../components/Navbar"
import OpenDayModal from "../../components/OpenDayModal"
import SyllabusModal from "../../components/SyllabusModal"
import Banner from "./Banner"
import Clients from "./Clients"
import Events from "./Events"
import Ivyarc from "./Ivyarc"
import RecentBlogs from "./RecentBlogs"
import Talent from "./Talent"
import Testimonials from "./Testimonials"
import TopCourses from "./TopCourses"
import WhyUs from "./WhyUs"

const Homepage = () => {
  const [showSyllabusModal, setShowSyllabusModal] = useState(false)
  const [showOpendayModal, setShowOpendayModal] = useState(false)

  return (
    <Box sx={{ margin: "0px", padding: "0px", cursor: "default" }}>
      <Helmet>
        <title>ARC Digital</title>
      </Helmet>

      {/**modals */}
      <SyllabusModal
        showSyllabusModal={showSyllabusModal}
        setShowSyllabusModal={setShowSyllabusModal}
      />

      <OpenDayModal
        showOpendayModal={showOpendayModal}
        setShowOpendayModal={setShowOpendayModal}
      />

      <Box sx={{ backgroundColor: "#FFFFFF" }}>
        <Navbar page="home" textColor="#1A1F2B" />

        <Banner />
      </Box>
      <Clients />
      <WhyUs />
      <Ivyarc />
      <TopCourses setShowSyllabusModal={setShowSyllabusModal} />
      <Talent />
      <Testimonials />
      <Events setShowOpendayModal={setShowOpendayModal} />
      <RecentBlogs />
      <Footer />
    </Box>
  )
}

export default Homepage
