import Layout from "./layout/Layout"
import { Routes, Route } from "react-router-dom"
import Selp from "./pages/Selp"
import Registration from "./pages/Registration"
import ContactUs from "./pages/ContactUs"
import Services from "./pages/Services"
import AboutUs from "./pages/AboutUs"
import Sef from "./pages/Sef"
import TalentSolution from "./pages/TalentSolution"
import ViewJobs from "./pages/TalentSolution/ViewJobs"
import Homepage from "./pages/Homepage"
import Blog from "./pages/Blog"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import UXreview1 from "./pages/UXreview1"
import ScrollToTop from "./layout/ScrollToTop"
import TagManager from "react-gtm-module"
import { HelmetProvider } from "react-helmet-async"
import HireTalent from "./pages/HireTalents"
import Interviews from "./pages/Interviews"

const queryClient = new QueryClient()

const tagManagerArgs = {
  gtmId: "UA-179932347-1",
}

TagManager.initialize(tagManagerArgs)

function App() {
  window.dataLayer.push({
    event: "pageview",
  })

  return (
    <QueryClientProvider client={queryClient}>
      <ScrollToTop>
        <HelmetProvider>
          <Routes>
            <Route element={<Layout />}>
              <Route path="/" element={<Homepage />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/uxreview1" element={<UXreview1 />} />
              <Route path="/courses/selp" element={<Selp />} />
              <Route path="/registration" element={<Registration />} />
              <Route path="/services" element={<Services />} />
              <Route path="/about_us" element={<AboutUs />} />
              <Route path="/contact_us" element={<ContactUs />} />
              <Route path="/courses/sef" element={<Sef />} />
              <Route path="/talent_solution" element={<TalentSolution />} />
              <Route path="/talent_solution/jobs" element={<ViewJobs />} />
              <Route path="/hire_talents" element={<HireTalent />} />
              <Route path="/interviews" element={<Interviews />} />
            </Route>
          </Routes>
        </HelmetProvider>
      </ScrollToTop>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  )
}

export default App
