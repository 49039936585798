import { Box, Typography } from "@mui/material";
import React from "react";

export const Section1 = () => {
  return (
    <Box
      sx={{
        padding: {
          xs: "2.5rem 1.5rem 1rem 1.5rem",
          sm: "2.5rem 3rem 1rem 3rem",
          md: "2.5rem 6rem 1rem 6rem",
        },
      }}
    >
      <Box textAlign="center">
        <Typography
          variant="subtitle1"
          fontFamily="Inter"
          fontWeight="600"
          color="#002E5F"
        >
          Published 14th of May, 2023.
        </Typography>
        <Typography
          variant="h4"
          fontFamily="Inter"
          fontWeight="600"
          marginTop="1rem"
        >
          An Introduction to Artificial Intelligence (AI) and Machine Learning (ML)
        </Typography>
        <Typography
          variant="body1"
          fontFamily="Inter"
          fontWeight="400"
          color="#667085"
          marginTop="1rem"
        >
          AI and ML are revolutionizing industries and daily life.
        </Typography>
      </Box>
      <Box marginTop="3rem">
        <img
          height="auto"
          width="100%"
          src={require("../../assets/Office1.png")}
          alt=""
          loading="lazy"
        />
      </Box>
      <Typography
        variant="h5"
        fontWeight="600"
        fontFamily="Inter"
        marginTop="5rem"
      >
        Introduction
      </Typography>
      <Typography
        variant="body1"
        fontFamily="Manrope"
        fontWeight="400"
        color="#667085"
        marginTop="1rem"
      >
        Artificial Intelligence (AI) and Machine Learning (ML) are transforming
        industries and our daily lives. Let's explore key aspects of AI and ML,
        including advancements, applications, ethics, and challenges. varius id.
      </Typography>
      <Typography
        variant="body1"
        fontFamily="Manrope"
        fontWeight="400"
        color="#667085"
        marginTop="1rem"
      >
        AI and ML are revolutionizing industries and daily life. Let's explore
        their advancements, applications, and ethical considerations.
      </Typography>
      <Box marginTop="3rem">
        <img
          height="auto"
          width="100%"
          src={require("../../assets/Office2.png")}
          alt=""
          loading="lazy"
        />
      </Box>
      <Box marginTop="3rem" borderLeft="3px solid #6941C6">
        <Box marginLeft="1rem">
          <Typography
            variant="h6"
            fontFamily="Inter"
            fontWeight="600"
            fontStyle="italic"
          >
            “In a world older and more complete than ours they move finished and
            complete, gifted with extensions of the senses we have lost or never
            attained, living by voices we shall never hear.”
          </Typography>
          <Typography
            variant="subtitle2"
            fontFamily="Inter"
            fontWeight="500"
            color="#667085"
            marginTop="2rem"
          >
            — Abai Ayomikun, Frontend Web-Developer
          </Typography>
        </Box>
      </Box>
      <Typography
        variant="h5"
        fontWeight="600"
        fontFamily="Inter"
        marginTop="3rem"
      >
        Advancement
      </Typography>
      <Typography
        variant="body1"
        fontFamily="Manrope"
        fontWeight="400"
        color="#667085"
        marginTop="1rem"
        ml="0.2rem"
      >
        1. AI and ML have made remarkable strides.
      </Typography>
      <Typography
        variant="body1"
        fontFamily="Manrope"
        fontWeight="400"
        color="#667085"
        mt="0.5rem"
      >
        2. Reinforcement Learning empowers AI models to learn through trial and
        error.
      </Typography>
      <Typography
        variant="body1"
        fontFamily="Manrope"
        fontWeight="400"
        color="#667085"
        mt="0.5rem"
      >
        3.Voice assistants, smart devices, and chatbots enhance convenience.
      </Typography>
      <Typography
        variant="h5"
        fontWeight="600"
        fontFamily="Inter"
        marginTop="3rem"
      >
        Applications
      </Typography>
      <Typography
        variant="body1"
        fontFamily="Manrope"
        fontWeight="400"
        color="#667085"
        marginTop="1rem"
        ml="0.2rem"
      >
        1. AI and ML find applications in healthcare, finance, transportation,
        and smart cities.
      </Typography>
      <Typography
        variant="body1"
        fontFamily="Manrope"
        fontWeight="400"
        color="#667085"
        mt="0.5rem"
      >
        2. They aid in medical diagnostics, fraud detection, self-driving cars,
        and efficient urban planning.
      </Typography>
      <Typography
        variant="body1"
        fontFamily="Manrope"
        fontWeight="400"
        color="#667085"
        mt="0.5rem"
      >
        3.AI and ML impact healthcare, finance, transportation, and smart
        cities.
      </Typography>
      <Typography
        variant="body1"
        fontFamily="Manrope"
        fontWeight="400"
        color="#667085"
        mt="0.5rem"
      >
        4.They aid in diagnostics, fraud detection, self-driving cars, and urban
        planning.
      </Typography>
      <Typography
        variant="h5"
        fontWeight="600"
        fontFamily="Inter"
        marginTop="3rem"
      >
        Ethical Consideratons
      </Typography>
      <Typography
        variant="body1"
        fontFamily="Manrope"
        fontWeight="400"
        color="#667085"
        marginTop="1rem"
        ml="0.2rem"
      >
        1. Addressing bias, privacy, and accountability are vital.
      </Typography>
      <Typography
        variant="body1"
        fontFamily="Manrope"
        fontWeight="400"
        color="#667085"
        mt="0.5rem"
      >
        2. Fairness and transparency must be ensured in AI systems.Protecting
        privacy and developing ethical frameworks are vital.
      </Typography>
      <Typography
        variant="body1"
        fontFamily="Manrope"
        fontWeight="400"
        color="#667085"
        mt="0.5rem"
      >
        3.Transparency and accountability in AI systems must be prioritized.
      </Typography>
      <Box marginTop="3rem">
        <img
          height="auto"
          width="100%"
          src={require("../../assets/Office3.png")}
          alt=""
          loading="lazy"
        />
      </Box>
      <Typography
        variant="h5"
        fontWeight="600"
        fontFamily="Inter"
        marginTop="3rem"
      >
        Challenges
      </Typography>
      <Typography
        variant="body1"
        fontFamily="Manrope"
        fontWeight="400"
        color="#667085"
        marginTop="1rem"
        ml="0.2rem"
      >
        1. Data quality, bias, and interpretability
      </Typography>
      <Typography
        variant="body1"
        fontFamily="Manrope"
        fontWeight="400"
        color="#667085"
        mt="0.5rem"
      >
        2. Safety, security, and job displacement need attention.
      </Typography>
      <Typography
        variant="body1"
        fontFamily="Manrope"
        fontWeight="400"
        color="#667085"
        mt="0.5rem"
      >
        3.Upskilling the workforce is essential.
      </Typography>
      <Box borderBottom="2px solid #EAECF0">
        <Typography
          variant="h5"
          fontWeight="600"
          fontFamily="Inter"
          marginTop="2rem"
        >
          Conclusion
        </Typography>
        <Typography
          variant="body1"
          fontFamily="Manrope"
          fontWeight="400"
          color="#667085"
          marginTop="0.5rem"
          ml="0.2rem"
        >
          AI and ML hold tremendous potential but require responsible
          development. By addressing challenges and prioritizing ethical
          considerations, we can create a positive future leveraging these
          transformative technologies.
        </Typography>

        <Typography
          variant="body1"
          fontWeight="600"
          fontFamily="Inter"
          marginTop="2rem"
          mb="2rem"
        >
          Disclaimer : Information reflects trends up until September 2021, and
          the field of AI and ML continues to evolve rapidly.
        </Typography>
      </Box>
    </Box>
  );
};

export default Section1;
