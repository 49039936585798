import { useState } from "react"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { useNavigate } from "react-router-dom"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Collapse from "@mui/material/Collapse"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import { FiChevronDown, FiMenu, FiX } from "react-icons/fi"
import { ExpandLess, ExpandMore } from "@mui/icons-material"

const Navbar = ({ page, textColor }) => {
  const navigate = useNavigate()
  const [showSubMenu, setShowSubMenu] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorEl2, setAnchorEl2] = useState(null)
  const open = Boolean(anchorEl)
  const open2 = Boolean(anchorEl2)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleCourseClick = event => {
    setAnchorEl2(event.currentTarget)
  }
  const handleCourseClose = () => {
    setAnchorEl2(null)
  }

  return (
    <AppBar
      sx={{
        backgroundColor: "inherit",
        boxShadow: "0px 1px 2px 0px rgb(0 0 0 / 0.05)",
        padding: {
          xs: "0rem 1.5rem",
          sm: "0rem 3rem",
          md: "0rem 4.5rem",
          lg: "0rem 6rem",
        },
      }}
      position="static"
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        disableGutters
      >
        <Typography
          variant="h6"
          fontWeight="800"
          sx={{
            color: textColor,
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        >
          IVYARC
        </Typography>

        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          <Typography
            variant="subtitle1"
            fontWeight="500"
            sx={{
              color: page === "blog" ? "#FF7801" : textColor,
              marginRight: "1.25rem",
              cursor: "pointer",
              "&:hover": {
                transform: page === "blog" ? "scale(1)" : "scale(0.875)",
                color: "#FF7801",
              },
            }}
            onClick={() => navigate("/blog")}
          >
            Blog
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              color: page === "course" ? "#FF7801" : textColor,
              "&:hover": {
                transform: page === "course" ? "scale(1)" : "scale(0.875)",
                color: "#FF7801",
              },
            }}
            onClick={handleCourseClick}
          >
            <Typography
              variant="subtitle1"
              fontWeight="500"
              sx={{
                marginRight: "0.5rem",
              }}
            >
              Courses
            </Typography>
            <FiChevronDown
              style={{
                marginRight: "1.25rem",
              }}
            />
          </Box>

          <Menu
            id="course-menu"
            anchorEl={anchorEl2}
            open={open2}
            onClose={handleCourseClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{ display: { xs: "none", md: "flex" } }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              onClick={() => {
                handleCourseClose()
                navigate("/courses/sef")
              }}
            >
              SEF
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleCourseClose()
                navigate("/courses/selp")
              }}
            >
              SELP
            </MenuItem>
            <MenuItem onClick={handleCourseClose}>Product Management</MenuItem>
            <MenuItem onClick={handleCourseClose}>UI/UX</MenuItem>
          </Menu>

          <Typography
            variant="subtitle1"
            fontWeight="500"
            sx={{
              color: page === "services" ? "#FF7801" : textColor,
              marginRight: "1.25rem",
              cursor: "pointer",
              "&:hover": {
                transform: page === "services" ? "scale(1)" : "scale(0.875)",
                color: "#FF7801",
              },
            }}
            onClick={() => navigate("/services")}
          >
            Services
          </Typography>
          <Typography
            variant="subtitle1"
            fontWeight="500"
            sx={{
              marginRight: "1.25rem",
              color: page === "talent" ? "#FF7801" : textColor,
              cursor: "pointer",
              "&:hover": {
                transform: page === "talent" ? "scale(1)" : "scale(0.875)",
                color: "#FF7801",
              },
            }}
            onClick={() => navigate("/talent_solution")}
          >
            Talent Solution
          </Typography>
          <Typography
            variant="subtitle1"
            fontWeight="500"
            sx={{
              marginRight: "1.25rem",
              color: page === "about" ? "#FF7801" : textColor,
              cursor: "pointer",
              "&:hover": {
                transform: page === "about" ? "scale(1)" : "scale(0.875)",
                color: "#FF7801",
              },
            }}
            onClick={() => navigate("/about_us")}
          >
            About Us
          </Typography>
          <Typography
            variant="subtitle1"
            fontWeight="500"
            sx={{
              color: page === "contact" ? "#FF7801" : textColor,
              cursor: "pointer",
              "&:hover": {
                transform: page === "contact" ? "scale(1)" : "scale(0.875)",
                color: "#FF7801",
              },
            }}
            onClick={() => navigate("/contact_us")}
          >
            Contact Us
          </Typography>
        </Box>

        <Box sx={{ display: { xs: "flex", md: "none" } }}>
          <Box onClick={handleClick}>
            {open ? (
              <FiX size={24} color={textColor} style={{ cursor: "pointer" }} />
            ) : (
              <FiMenu
                size={24}
                color={textColor}
                style={{ cursor: "pointer" }}
              />
            )}
          </Box>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{ display: { md: "none" }, width: "300px" }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose()
                navigate("/blog")
              }}
            >
              Blog
            </MenuItem>
            <MenuItem
              sx={{ display: "flex" }}
              onClick={() => {
                setShowSubMenu(!showSubMenu)
              }}
            >
              Courses
              {showSubMenu ? <ExpandLess /> : <ExpandMore />}
            </MenuItem>
            <Collapse in={showSubMenu} timeout="auto" unmountOnExit>
              <List disablePadding>
                <ListItem
                  sx={{
                    pl: "2rem",
                    py: "0.25rem",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                  }}
                  onClick={() => {
                    navigate("/courses/sef")
                  }}
                >
                  <ListItemText primary="SEF" />
                </ListItem>
                <ListItem
                  sx={{
                    pl: "2rem",
                    py: "0.25rem",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                  }}
                  onClick={() => {
                    navigate("/courses/selp")
                  }}
                >
                  <ListItemText primary="SELP" />
                </ListItem>
                <ListItem
                  sx={{
                    pl: "2rem",
                    py: "0.25rem",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                  }}
                >
                  <ListItemText primary="Product Management" />
                </ListItem>
                <ListItem
                  sx={{
                    pl: "2rem",
                    py: "0.25rem",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                  }}
                >
                  <ListItemText primary="UI/UX" />
                </ListItem>
              </List>
            </Collapse>
            <MenuItem
              onClick={() => {
                handleClose()
                navigate("/services")
              }}
            >
              Services
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose()
                navigate("/talent_solution")
              }}
            >
              Talent Solution
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose()
                navigate("/about_us")
              }}
            >
              About Us
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose()
                navigate("contact_us")
              }}
            >
              Contact Us
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default Navbar
