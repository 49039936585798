import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Job from "../../components/Job"
import { useNavigate } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { fetcher } from "../../api/fetcher"

const Jobs = () => {
  const navigate = useNavigate()
  const { isLoading, data, isError, error } = useQuery(["jobs"], () =>
    fetcher("jobs")
  )

  return (
    <Box
      sx={{
        padding: {
          xs: "3rem 1.5rem",
          sm: "3rem 3rem",
          md: "3rem 4.5rem",
          lg: "3rem 6rem",
        },
        backgroundColor: "#FFFFFF",
      }}
    >
      <Typography variant="h4" sx={{ fontWeight: "600" }}>
        Jobs Available
      </Typography>

      <Box
        sx={{
          mt: "2rem",
          mx: "auto",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: { xs: "center", sm: "start" },
          width: "100%",
        }}
      >
        {data?.data.slice(0, 4).map((job, index) => (
          <Job job={job} key={index} />
        ))}
      </Box>

      <Box sx={{ display: "flex", justifyContent: "right" }}>
        <Button
          variant="text"
          sx={{ color: "#FF7801", textTransform: "none" }}
          onClick={() => navigate("jobs")}
        >
          View All
        </Button>
      </Box>
    </Box>
  )
}

export default Jobs
