import { Box } from "@mui/material"
import React from "react"
import { Helmet } from "react-helmet-async"
import Section1 from "../components/Blog/Section1"
import Section2 from "../components/Blog/Section2"
import Section3 from "../components/Blog/Section3"
import Footer from "../components/Footer"
import Navbar from "../components/Navbar"

export const UXreview1 = () => {
  return (
    <Box>
      <Helmet>
        <title>ARC Digital | Blog</title>
      </Helmet>

      <Navbar page="blog" textColor="#1A1F2B" />
      <Section1 />
      <Section2 />
      <Section3 />
      <Footer />
    </Box>
  )
}

export default UXreview1
