import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import { useNavigate } from "react-router-dom"

const Works = ({ setShowRegistrationModal }) => {
  const navigate = useNavigate()

  return (
    <Box>
      <Typography
        variant="body1"
        gutterBottom
        sx={{ color: "#FF7801", fontWeight: "600" }}
      >
        Three Simple Steps
      </Typography>
      <Box>
        <Box sx={{ display: "flex", marginTop: "1rem" }}>
          <Box sx={{ marginRight: "1rem" }}>
            <img
              src={require("../../../assets/profile-circle.png")}
              alt=""
              loading="lazy"
            />
          </Box>
          <Box>
            <Typography variant="body2" sx={{ fontWeight: "600" }} gutterBottom>
              Create Profile
            </Typography>
            <Typography variant="body2">
              Complete a form that tells us about your skill level, contact
              details, and motivations for the taking the course.
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: "1rem" }}>
          <Box sx={{ marginRight: "1rem" }}>
            <img
              src={require("../../../assets/video.png")}
              alt=""
              loading="lazy"
            />
          </Box>
          <Box>
            <Typography variant="body2" sx={{ fontWeight: "600" }} gutterBottom>
              Take a Screening Call
            </Typography>
            <Typography variant="body2">
              We gauge your skill level to fine-tune your experience. We pace
              ourselves according to your needs to ensure that you can absorb
              knowledge at a good rate.
            </Typography>
          </Box>
        </Box>

        {/* <Box sx={{ display: "flex", marginTop: "1rem" }}>
          <Box sx={{ marginRight: "1rem" }}>
            <img
              src={require("../../../assets/wallet-money.png")}
              alt=""
              loading="lazy"
            />
          </Box>
          <Box>
            <Typography variant="body2" sx={{ fontWeight: "600" }} gutterBottom>
              Pay for the Course
            </Typography>
            <Typography variant="body2">
              If you pass the screening call, you will be provided with a
              payment link for the course, sent directly to your provided email
              address.
            </Typography>
          </Box>
        </Box> */}

        <Box sx={{ display: "flex", marginTop: "1rem" }}>
          <Box sx={{ marginRight: "1rem" }}>
            <img
              src={require("../../../assets/book.png")}
              alt=""
              loading="lazy"
            />
          </Box>
          <Box>
            <Typography variant="body2" sx={{ fontWeight: "600" }} gutterBottom>
              Commence Programme
            </Typography>
            <Typography variant="body2">
              Once your payment is done, you are free to start the programme,
              being sorted into relevant teams, meeting your teammates, and
              start your software engineering journey.
            </Typography>
          </Box>
        </Box>

        {/* <Box sx={{ display: "flex", marginTop: "1rem" }}>
          <Box sx={{ marginRight: "1rem" }}>
            <img
              src={require("../../../assets/book.png")}
              alt=""
              loading="lazy"
            />
          </Box>
          <Box>
            <Typography variant="body2" sx={{ fontWeight: "600" }} gutterBottom>
              Scholarship Terms
            </Typography>
            <Typography variant="body2">
              A. 100% Scholarship to the first 12 dedicated applicants. B. 50%
              discount to all applicant until end of November. C. 25% discount
              after November.
            </Typography>
          </Box>
        </Box> */}

        <Button
          sx={{
            margin: "1rem 0rem 0rem 2.75rem",
            backgroundColor: "#002E5F",
            padding: "0.5rem 3.5rem",
          }}
          variant="contained"
          onClick={() => setShowRegistrationModal(true)}
        >
          Enroll Now
        </Button>
      </Box>
    </Box>
  )
}

export default Works
