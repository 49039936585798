import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import TextField from "@mui/material/TextField"
import MenuItem from "@mui/material/MenuItem"
import Checkbox from "@mui/material/Checkbox"
import { styled } from "@mui/material/styles"
import { paymentOptions } from "../../utils/data"

const StyledTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    color: "white",
  },
  " .Mui-error": {
    color: "#ed6c02",
  },
  "& .MuiSvgIcon-root": {
    color: "white",
  },
  "& label": {
    color: "white",
  },
  "&:hover label": {
    fontWeight: 700,
  },
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
      borderWidth: 2,
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
    "&.Mui-error fieldset": {
      borderColor: "#ed6c02",
    },
  },
})

const Details = ({ formik, checked, setChecked }) => {
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        width: {
          xs: "80%",
          md: "50%",
        },
        mt: "3rem",
        mx: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/**background */}
      <StyledTextField
        variant="outlined"
        label="Tell us about yourself - background and experience"
        id="about_yourself"
        fullWidth
        value={formik.values.about_yourself}
        onChange={formik.handleChange("about_yourself")}
        onBlur={formik.handleBlur}
        error={
          formik.touched.about_yourself && Boolean(formik.errors.about_yourself)
        }
        helperText={
          formik.touched.about_yourself && formik.errors.about_yourself
        }
        multiline
        maxRows={4}
      />

      {/**motivation */}
      <StyledTextField
        variant="outlined"
        id="motivation"
        label="What's your motivation for applying for the SELP Programme?"
        fullWidth
        value={formik.values.motivation}
        onChange={formik.handleChange("motivation")}
        onBlur={formik.handleBlur}
        error={formik.touched.motivation && Boolean(formik.errors.motivation)}
        helperText={formik.touched.motivation && formik.errors.motivation}
        sx={{ mt: "2rem" }}
        multiline
        maxRows={4}
      />

      {/**overcoming hinderances */}
      <StyledTextField
        variant="outlined"
        id="overcoming-hinderances"
        label="How do you think you will stay motivated over 12-weeks and overcome any hinderances?"
        fullWidth
        value={formik.values.staying_motivated}
        onChange={formik.handleChange("staying_motivated")}
        onBlur={formik.handleBlur}
        error={
          formik.touched.staying_motivated &&
          Boolean(formik.errors.staying_motivated)
        }
        helperText={
          formik.touched.staying_motivated && formik.errors.staying_motivated
        }
        sx={{ mt: "2rem" }}
        multiline
        maxRows={4}
      />

      {/**training expectations */}
      <StyledTextField
        variant="outlined"
        id="training expectations"
        label="What are your expectations during the training and after the training?"
        fullWidth
        value={formik.values.expectations_during_training}
        onChange={formik.handleChange("expectations_during_training")}
        onBlur={formik.handleBlur}
        error={
          formik.touched.expectations_during_training &&
          Boolean(formik.errors.expectations_during_training)
        }
        helperText={
          formik.touched.expectations_during_training &&
          formik.errors.expectations_during_training
        }
        sx={{ mt: "2rem" }}
        multiline
        maxRows={4}
      />

      {/**computer access */}
      <StyledTextField
        variant="outlined"
        id="computer-access"
        label="Do you have access to a computer and good internet connectivity?"
        fullWidth
        value={formik.values.computer_access}
        onChange={formik.handleChange("computer_access")}
        onBlur={formik.handleBlur}
        error={
          formik.touched.computer_access &&
          Boolean(formik.errors.computer_access)
        }
        helperText={
          formik.touched.computer_access && formik.errors.computer_access
        }
        sx={{ mt: "2rem" }}
        multiline
        maxRows={4}
      />

      {/**hear about programme */}
      <StyledTextField
        variant="outlined"
        id="hear-about-programme"
        label="How did you hear about the programme?"
        fullWidth
        value={formik.values.hear_about_programme}
        onChange={formik.handleChange("hear_about_programme")}
        onBlur={formik.handleBlur}
        error={
          formik.touched.hear_about_programme &&
          Boolean(formik.errors.hear_about_programme)
        }
        helperText={
          formik.touched.hear_about_programme &&
          formik.errors.hear_about_programme
        }
        sx={{ mt: "2rem" }}
        multiline
        maxRows={4}
      />

      {/**funding */}
      <StyledTextField
        variant="outlined"
        id="funding"
        label="How will you be funding the programme?"
        value={formik.values.funding}
        onChange={formik.handleChange("funding")}
        onBlur={formik.handleBlur}
        error={formik.touched.funding && Boolean(formik.errors.funding)}
        helperText={formik.touched.funding && formik.errors.funding}
        fullWidth
        sx={{ mt: "2rem" }}
      />

      {/**payment period */}
      <StyledTextField
        variant="outlined"
        id="payment-period"
        label="When will you commit (pay)?"
        value={formik.values.payment_period}
        onChange={formik.handleChange("payment_period")}
        onBlur={formik.handleBlur}
        error={
          formik.touched.payment_period && Boolean(formik.errors.payment_period)
        }
        helperText={
          formik.touched.payment_period && formik.errors.payment_period
        }
        fullWidth
        sx={{ mt: "2rem" }}
      />

      {/**payment option */}
      <StyledTextField
        variant="outlined"
        id="payment-option"
        label="Preferred payment option"
        fullWidth
        value={formik.values.payment_option}
        onChange={formik.handleChange("payment_option")}
        onBlur={formik.handleBlur}
        error={
          formik.touched.payment_option && Boolean(formik.errors.payment_option)
        }
        helperText={
          formik.touched.payment_option && formik.errors.payment_option
        }
        sx={{ mt: "2rem" }}
        select
      >
        {paymentOptions.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </StyledTextField>

      <Box
        sx={{
          mt: "2rem",
          display: "flex",
          width: "100%",
          alignItems: "start",
        }}
      >
        <Checkbox
          checked={checked}
          onChange={e => setChecked(e.target.checked)}
          inputProps={{ "aria-label": "controlled" }}
          sx={{
            color: "white",
            "&.Mui-checked": {
              color: "white",
            },
            mr: "1rem",
          }}
        />

        <Box>
          <Typography
            variant="body2"
            sx={{ color: "white", fontWeight: "200" }}
          >
            I agree to receive occasional communication from ARC.
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "white", fontWeight: "200" }}
          >
            You can unsubscribe from these communications at ay time.
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "white", fontWeight: "200" }}
            gutterBottom
          >
            For more information on how to unsubscribe, our privacy practice and
            how we are committed to protecting and respecting your privacy,
            please review our Privacy Policy.
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "white", fontWeight: "200", mt: "0.5rem" }}
          >
            By clicking submit, you consent to allow ARC to store and process
            the personal information submitted to us to provide you with the
            service requested.
          </Typography>
        </Box>
      </Box>

      <Button
        variant="contained"
        sx={{ backgroundColor: "#FF7801", mt: "2rem", py: "0.75rem" }}
        fullWidth
        disabled={!checked}
        onClick={e => {
          formik.handleSubmit(e)
        }}
      >
        SUBMIT
      </Button>
    </Box>
  )
}

export default Details
