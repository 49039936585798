import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import TextField from "@mui/material/TextField"
import Divider from "@mui/material/Divider"
import Link from "@mui/material/Link"
import { FiPhone, FiMail } from "react-icons/fi"
import axios from "../api/axios"
import * as yup from "yup"
import { useFormik } from "formik"
import Swal from "sweetalert2"
import { useNavigate } from "react-router-dom"

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("This field is required"),
})

const Footer = () => {
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      const response = await axios
        .post("newsletter", {
          email: values.email.toLowerCase(),
        })
        .catch(error => {
          // console.error(error.response.data.message)
          Swal.fire({
            toast: true,
            icon: "error",
            title: error.response.data.message,
            position: "top-right",
            timer: 5000,
            showConfirmButton: false,
            // width: "auto",
          })
        })
      if (response.status === 201) {
        resetForm()
        Swal.fire({
          toast: true,
          // icon: "success",
          title: "You have successfully subscribed to our newsletter.",
          position: "top-right",
          timer: 5000,
          showConfirmButton: false,
          width: "auto",
        })
      }
    },
  })

  return (
    <Box
      sx={{
        backgroundColor: "#002E5F",
        padding: {
          xs: "3rem 1.5rem",
          sm: "3rem 3rem",
          md: "3rem 4.5rem",
          lg: "3rem 6rem",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Box
          sx={{
            width: {
              xs: "50%",
              sm: "33.33%",
              md: "25%",
            },
            mb: {
              xs: "1.5rem",
              md: "0rem",
            },
          }}
        >
          <Typography
            variant="h5"
            sx={{ color: "#FFFFFF", fontWeight: "700", cursor: "pointer" }}
            gutterBottom
            onClick={() => navigate("/")}
          >
            IVYARC
          </Typography>
          <Typography variant="body2" sx={{ color: "#FFFFFF" }}>
            Our purpose is to help clients deploy technology to deliver outcomes
            precisely aligned to their business goals.
          </Typography>
        </Box>

        <Box
          sx={{
            width: {
              xs: "45%",
              sm: "30%",
              md: "auto",
            },
            mb: {
              xs: "1.5rem",
              sm: "0rem",
            },
          }}
        >
          <Typography variant="subtitle2" sx={{ color: "#FFFFFF" }}>
            COMPANY
          </Typography>
          <List component="ul" sx={{ color: "#FFFFFF" }} dense>
            <ListItem
              sx={{ display: "list-item", cursor: "pointer" }}
              disableGutters
              onClick={() => navigate("/about_us")}
            >
              <ListItemText primary="About" />
            </ListItem>
            <ListItem
              sx={{ display: "list-item", cursor: "pointer" }}
              disableGutters
              onClick={() => navigate("/courses/selp")}
            >
              <ListItemText primary="SELP" />
            </ListItem>
            <ListItem
              sx={{ display: "list-item", cursor: "pointer" }}
              disableGutters
              onClick={() => navigate("/courses/sef")}
            >
              <ListItemText primary="SEF" />
            </ListItem>
            <ListItem
              sx={{ display: "list-item", cursor: "pointer" }}
              disableGutters
              onClick={() => navigate("/services")}
            >
              <ListItemText primary="Career" />
            </ListItem>
          </List>
        </Box>

        <Box
          sx={{
            width: {
              xs: "50%",
              sm: "30%",
              md: "auto",
            },
          }}
        >
          <Typography variant="subtitle2" sx={{ color: "#FFFFFF" }}>
            HELP
          </Typography>
          <List component="ul" sx={{ color: "#FFFFFF" }} dense>
            <ListItem
              sx={{ display: "list-item", cursor: "pointer" }}
              disableGutters
              onClick={() => navigate("/contact_us")}
            >
              <ListItemText primary="Customer Support" />
            </ListItem>
            <ListItem
              sx={{ display: "list-item", cursor: "pointer" }}
              disableGutters
            >
              <ListItemText primary="Terms & Conditions" />
            </ListItem>
            <ListItem
              sx={{ display: "list-item", cursor: "pointer" }}
              disableGutters
            >
              <ListItemText primary="Privacy Policy" />
            </ListItem>
          </List>
        </Box>

        <Box
          component="form"
          sx={{
            width: {
              xs: "45%",
              sm: "33.33%",
              md: "25%",
            },
          }}
        >
          <Typography variant="body2" sx={{ color: "#FFFFFF" }}>
            NEWSLETTER
          </Typography>
          <TextField
            id="email"
            value={formik.values.email}
            onChange={formik.handleChange("email")}
            fullWidth
            size="small"
            placeholder="Enter your email address"
            sx={{
              backgroundColor: "#FFFFFF",
              borderRadius: "0.5rem",
              mt: "1rem",
            }}
          />
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#FF7801",
              "&:hover": {
                backgroundColor: "#FF7801",
              },
              width: "100%",
              mt: "1rem",
              fontSize: {
                xs: "10px",
                sm: "14px",
              },
            }}
            onClick={e => {
              formik.handleSubmit(e)
            }}
          >
            Subscribe Now
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          position: "relative",
          py: "1rem",
          px: { xs: "1rem", md: "0rem" },
          mt: "1rem",
        }}
      >
        <Box
          sx={{
            color: "#FFFFFF",
            display: "flex",
            alignItems: "center",
            mr: "1rem",
            mt: {
              xs: "0.5rem",
              sm: "0rem",
            },
          }}
        >
          <FiPhone />
          <Link
            href="tel:+2348089895660"
            variant="body2"
            sx={{ ml: "0.5rem" }}
            color="inherit"
            underline="hover"
          >
            Nigeria: +234 808 989 5660
          </Link>
        </Box>
        <Box
          sx={{
            color: "#FFFFFF",
            display: "flex",
            alignItems: "center",
            mr: "1rem",
            mt: {
              xs: "0.5rem",
              sm: "0rem",
            },
          }}
        >
          <FiPhone />
          <Link
            href="tel:+4477444151270"
            variant="body2"
            sx={{ ml: "0.5rem" }}
            color="inherit"
            underline="hover"
          >
            United Kingdom: +44 7744 4151 270
          </Link>
        </Box>
        <Box
          sx={{
            color: "#FFFFFF",
            display: "flex",
            alignItems: "center",
            mt: {
              xs: "0.5rem",
              sm: "0rem",
            },
          }}
        >
          <FiMail />
          <Link
            href="mailto:info@ivyarc.com"
            variant="body2"
            sx={{ ml: "0.5rem" }}
            color="inherit"
            underline="hover"
          >
            info@ivyarc.com
          </Link>
        </Box>

        <Box
          sx={{
            position: "absolute",
            right: { xs: 0, md: "1rem" },
            bottom: { xs: "1rem", md: "0.5rem" },
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            width: { md: "125px" },
            height: { xs: "125px", md: "auto" },
          }}
        >
          <Link
            href="https://twitter.com/ivyarcafrica"
            underline="none"
            target="_blank"
          >
            <img src={require("../assets/Twitter.png")} alt="twitter" />
          </Link>
          <Link
            href="https://www.facebook.com/ivyarcafrica/"
            underline="none"
            target="_blank"
          >
            <img src={require("../assets/Facebook.png")} alt="facebook" />
          </Link>

          <Link
            href="https://www.instagram.com/ivyarcafrica/"
            underline="none"
            target="_blank"
          >
            <img src={require("../assets/Instagram.png")} alt="instagram" />
          </Link>
          <Link
            href="https://www.linkedin.com/company/ivyarc/"
            underline="none"
            target="_blank"
          >
            <img
              src={require("../assets/LinkedIn.png")}
              alt="linkedin"
              width="28px"
              height="28px"
            />
          </Link>
        </Box>
      </Box>
      <Divider sx={{ backgroundColor: "#FFFFFF" }} />

      <Typography
        variant="body2"
        sx={{ color: "#FFFFFF", mt: "1rem", textAlign: "center" }}
      >
        &#169; Copyright 2022, All Rights Reserved by IVYARC
      </Typography>
    </Box>
  )
}

export default Footer
