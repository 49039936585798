import { Box } from "@mui/material"
import React from "react"
import Section1 from "../components/UXreview1/Section1"
import Section2 from "../components/UXreview1/Section2"
import Section3 from "../components/UXreview1/Section3"
import Section4 from "../components/UXreview1/Section4"
import Footer from "../components/Footer"
import Navbar from "../components/Navbar"

export const Blog = () => {
  return (
    <Box>
      <Navbar page="blog" textColor="#1A1F2B" />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Footer />
    </Box>
  )
}

export default Blog
