import { createContext, useState } from "react"

export const SyllabusContext = createContext()

const SyllabusContextProvider = ({ children }) => {
  const [prog, setProg] = useState("")

  return (
    <SyllabusContext.Provider value={{ prog, setProg }}>
      {children}
    </SyllabusContext.Provider>
  )
}

export default SyllabusContextProvider
