import { useState } from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import IconButton from "@mui/material/IconButton"
import axios from "../../api/axios"
import * as yup from "yup"
import { useFormik } from "formik"
import Swal from "sweetalert2"
import { FiX } from "react-icons/fi"
import { useNavigate } from "react-router-dom"
import Details from "./Details"
import About from "./About"
import DetailsTwo from "./DetailsTwo"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "100%", sm: "50%" },
  maxHeight: "90vh",
  bgcolor: "background.paper",
  border: "0px",
  boxShadow: 24,
  p: "2rem 0rem",
  outline: "none",
  overflow: "auto",
}

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const aboutSchema = yup.object({
  first_name: yup
    .string("Enter your first name")
    .required("This field is required")
    .min(3, "First name must be at least 3 characters"),
  last_name: yup
    .string("Enter your last name")
    .required("This field is required")
    .min(3, "Last name must be at least 3 characters"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("This field is required"),
  github: yup
    .string("Enter your github account")
    .required("This field is required"),
  experience: yup
    .string("Enter your programming experience")
    .required("This field is required"),
  commitment_level: yup
    .string("Enter your commitment level")
    .required("This field is required"),
  course: yup.string("Select a course").required("This field is required"),
  phone: yup
    .string("Enter your phone number")
    .matches(phoneRegExp, "Enter a valid phone number")
    .required("This field is required"),
  skills: yup
    .array(yup.string())
    .min(1, "Enter your tech skills")
    .required("This field is required"),
  about_yourself: yup
    .string("Enter your background and experience")
    .required("This field is required"),
})

const detailsSchema = yup.object({
  motivation: yup
    .string("Enter your motivation for applying for this programme")
    .required("This field is required"),
  staying_motivated: yup
    .string("Enter your means of staying motivated")
    .required("This field is required"),
  computer_access: yup
    .string("Enter your computer and internet connectivity access")
    .required("This field is required"),
})

const detailsTwoSchema = yup.object({
  expectations_during_training: yup
    .string("Enter your expectations for the training")
    .required("This field is required"),
  expectations_post_training: yup
    .string("Enter your post training expectations")
    .required("This field is required"),
  hear_about_programme: yup
    .string("Enter how you heard about the programme")
    .required("This field is required"),
  receive_communication: yup
    .boolean()
    .oneOf([true], "You must accept the terms and conditions"),
})

const RegistrationModal = ({
  showRegistrationModal,
  setShowRegistrationModal,
  api,
  setScrollIntoView,
}) => {
  const [step, setStep] = useState(1)
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      github: "",
      experience: "",
      commitment_level: "",
      course: "Software Engineering Foundation",
      phone: "",
      skills: [],
      about_yourself: "",
      expectations_post_training: "",
      motivation: "",
      staying_motivated: "",
      expectations_during_training: "",
      computer_access: "",
      hear_about_programme: "",
      receive_communication: false,
    },
    validationSchema:
      step === 1 ? aboutSchema : step === 2 ? detailsSchema : detailsTwoSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      if (step !== 3) {
        setStep(step + 1)
      }

      if (step === 3) {
        const response = await axios
          .post(`${api}`, {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email.toLowerCase(),
            github: values.github,
            experience: values.experience,
            commitment_level: values.commitment_level,
            course: values.course,
            phone: values.phone,
            skills: values.skills,
            expectations_post_training: values.expectations_post_training,
            about_yourself: values.about_yourself,
            motivation: values.motivation,
            staying_motivated: values.staying_motivated,
            expectations_during_training: values.expectations_during_training,
            computer_access: values.computer_access,
            hear_about_programme: values.hear_about_programme,
            receive_communication: values.receive_communication,
          })
          .catch(error => {
            // console.error(error.response)
            Swal.fire({
              toast: true,
              icon: "error",
              title: error.response.data.message,
              position: "top",
              timer: 5000,
              showConfirmButton: false,
              customClass: {
                container: "my-swal",
              },
              // width: "auto",
            })
          })
        if (response.status === 201) {
          Swal.fire({
            toast: true,
            // icon: "success",
            title: "You have been registered for this course successfully.",
            position: "top",
            timer: 5000,
            showConfirmButton: false,
            width: "auto",
            customClass: {
              container: "my-swal",
            },
          })
          resetForm()
          navigate(0)
        }
      }
    },
  })

  const displayStep = step => {
    switch (step) {
      case 1:
        return <About formik={formik} />
      case 2:
        return <Details formik={formik} step={step} setStep={setStep} />
      case 3:
        return (
          <DetailsTwo
            formik={formik}
            step={step}
            setStep={setStep}
            setShowRegistrationModal={setShowRegistrationModal}
            setScrollIntoView={setScrollIntoView}
          />
        )
      default:
    }
  }

  return (
    <Box>
      <Modal
        open={showRegistrationModal}
        onClose={() => {
          setShowRegistrationModal(false)
          // navigate(0)
        }}
        aria-labelledby="modal-modal-title"
        disableScrollLock
      >
        <Box sx={style}>
          <IconButton
            sx={{ position: "absolute", top: "0px", right: "0px" }}
            onClick={() => {
              setShowRegistrationModal(false)
              // navigate(0)
            }}
          >
            <FiX />
          </IconButton>

          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center", fontWeight: "700" }}
          >
            Registration Form
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            sx={{ textAlign: "center", fontWeight: "700" }}
          >
            NOTE: Please ensure to provide the following information
          </Typography>

          {displayStep(step)}
        </Box>
      </Modal>
    </Box>
  )
}

export default RegistrationModal
