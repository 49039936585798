import { useRef } from "react"
import Box from "@mui/material/Box"
import Footer from "../../components/Footer"
import Navbar from "../../components/Navbar"
import Benefits from "./Benefits"
import Clients from "./Clients"
import FindTalent from "./FindTalent"
import Jobs from "./Jobs"
import Jumbotron from "./Jumbotron"
import Testimonials from "./Testimonials"
import Faq from "./Faq"
import { Helmet } from "react-helmet-async"

const TalentSolution = () => {
  const talentRef = useRef()
  const testimonialsRef = useRef()

  return (
    <Box sx={{ margin: "0px", padding: "0px", cursor: "default" }}>
      <Helmet>
        <title>ARC Digital | Talent Solution</title>
      </Helmet>

      <Box sx={{ backgroundColor: "#FFFFFF" }}>
        <Navbar page="talent" textColor="#1A1F2B" />

        <Jumbotron talentRef={talentRef} />
      </Box>
      <Clients />
      <Benefits testimonialsRef={testimonialsRef} />
      <FindTalent talentRef={talentRef} />
      <Jobs />
      <Testimonials testimonialsRef={testimonialsRef} />
      <Faq />
      <Footer />
    </Box>
  )
}

export default TalentSolution
