import { useState, useEffect, useRef } from "react"
import Box from "@mui/material/Box"
import Navbar from "../../components/Navbar"
import Footer from "../../components/Footer"
import Benefits from "./Benefits"
import SelpModal from "../../components/SelpModal"
import SyllabusModal from "../../components/SyllabusModal"
import TopCourses from "./TopCourses"
import Clients from "./Clients"
import Alumni from "./Alumni"
import ProgrammeDetails from "./ProgrammeDetails"
import Jumbotron from "./Jumbotron"
import OpenDayModal from "../../components/OpenDayModal"
import RegistrationModal from "../../components/SefRegistration"
import Faq from "./Faq"
import { Helmet } from "react-helmet-async"

const Sef = () => {
  const [showModal, setShowModal] = useState(false)
  const [showSyllabusModal, setShowSyllabusModal] = useState(false)
  const [showOpendayModal, setShowOpendayModal] = useState(false)
  const [showRegistrationModal, setShowRegistrationModal] = useState(false)
  const [scrollIntoView, setScrollIntoView] = useState(false)
  const [course, setCourse] = useState("")

  const faqRef = useRef()

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setShowModal(true)
  //   }, 3000)
  //   return () => clearTimeout(timer)
  // }, [])

  useEffect(() => {
    const scrollToFaq = () => {
      if (scrollIntoView) {
        faqRef.current.scrollIntoView({
          behavior: "smooth",
        })
        setScrollIntoView(false)
      }
    }

    scrollToFaq()
  }, [scrollIntoView])

  return (
    <Box sx={{ margin: "0px", padding: "0px", cursor: "default" }}>
      <Helmet>
        <title>ARC Digital | SEF</title>
      </Helmet>

      {/**modals */}
      <SelpModal
        showModal={showModal}
        setShowModal={setShowModal}
        course={course}
      />
      <SyllabusModal
        showSyllabusModal={showSyllabusModal}
        setShowSyllabusModal={setShowSyllabusModal}
      />
      <OpenDayModal
        showOpendayModal={showOpendayModal}
        setShowOpendayModal={setShowOpendayModal}
      />
      <RegistrationModal
        showRegistrationModal={showRegistrationModal}
        setShowRegistrationModal={setShowRegistrationModal}
        api="sef"
        setScrollIntoView={setScrollIntoView}
      />

      {/**header */}
      <Box sx={{ backgroundColor: "#E6EAEF" }}>
        <Navbar page="course" textColor="#1A1F2B" />

        <Jumbotron
          setShowRegistrationModal={setShowRegistrationModal}
          setShowModal={setShowModal}
          setCourse={setCourse}
        />
      </Box>

      {/**clients */}
      <Clients />

      {/**tabs */}
      <ProgrammeDetails
        setShowSyllabusModal={setShowSyllabusModal}
        setShowRegistrationModal={setShowRegistrationModal}
      />

      {/**benefits */}
      <Benefits
        setShowOpendayModal={setShowOpendayModal}
        setShowRegistrationModal={setShowRegistrationModal}
      />

      {/**top courses */}
      <TopCourses setShowSyllabusModal={setShowSyllabusModal} />

      {/**alumni */}
      <Alumni />

      {/**faq */}
      <Faq faqRef={faqRef} />

      {/**footer */}
      <Footer />
    </Box>
  )
}

export default Sef
