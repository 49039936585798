import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

const Clients = () => {
  return (
    <Box
      sx={{
        padding: {
          xs: "3rem 1.5rem",
          sm: "3rem 3rem",
          md: "3rem 4.5rem",
          lg: "3rem 6rem",
        },
      }}
    >
      <Typography variant="h6" gutterBottom>
        Our Clients and Partnerships
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ position: "relative", px: "1rem" }}>
          <img
            src={require("../../assets/worka.png")}
            alt=""
            width="100%"
            height="auto"
            loading="lazy"
          />
        </Box>
        <Box sx={{ position: "relative", px: "1rem" }}>
          <img
            src={require("../../assets/xische.png")}
            alt=""
            width="100%"
            height="auto"
            loading="lazy"
          />
        </Box>
        <Box sx={{ position: "relative", px: "1rem" }}>
          <img
            src={require("../../assets/afrimash.png")}
            alt=""
            width="100%"
            height="auto"
            loading="lazy"
          />
        </Box>
      </Box>
    </Box>
  )
}

export default Clients
