import {
  AppBar,
  Box,
  Button,
  MenuItem,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material"
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import { useFormik } from "formik"
import Swal from "sweetalert2"
import axios from "../api/axios"
import { interviewerLinks } from "../utils/data"
import { useEffect } from "react"

const validationSchema = yup.object({
  name: yup
    .string("Enter your full name")
    .required("This field is required")
    .min(3, "Full name must be at least 3 characters"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("This field is required"),
  interviewer: yup
    .string("Select an interviewer")
    .required("This field is required"),
})

const Interviews = () => {
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      interviewer: "",
    },
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      const response = await axios
        .post("interview", {
          name: values.name,
          email: values.email,
          interviewer: values.interviewer,
        })
        .catch(error => {
          Swal.fire({
            toast: true,
            icon: "error",
            title: error.response.data.message,
            position: "top",
            timer: 5000,
            showConfirmButton: false,
            // width: "auto",
          })
        })
      if (response.status === 201) {
        resetForm()
        Swal.fire({
          toast: true,
          icon: "success",
          title: "Interviewer has been assigned successfully",
          position: "top",
          timer: 5000,
          showConfirmButton: false,
          width: "auto",
        })
      }
    },
  })

  return (
    <Box
      sx={{
        padding: "0px",
        margin: "0px",
        cursor: "default",
        backgroundColor: "#FFFFFF",
        minHeight: "100vh",
      }}
    >
      <Helmet>
        <title>ARC Digital | Interviews</title>
      </Helmet>

      <AppBar
        sx={{
          backgroundColor: "inherit",
          boxShadow: "0px 1px 2px 0px rgb(0 0 0 / 0.05)",
          padding: {
            xs: "0rem 1.5rem",
            sm: "0rem 3rem",
            md: "0rem 4.5rem",
            lg: "0rem 6rem",
          },
        }}
        position="static"
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          disableGutters
        >
          <Typography
            variant="h6"
            fontWeight="800"
            sx={{
              color: "#000000",
              cursor: "pointer",
            }}
            onClick={() => navigate("/")}
          >
            IVYARC
          </Typography>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          padding: {
            xs: "1.5rem 1.5rem",
            sm: "1.5rem 3rem",
            md: "1.5rem 4.5rem",
            lg: "1.5rem 6rem",
          },
        }}
      >
        <Typography
          variant="h6"
          gutterBottom
          fontWeight="600"
          sx={{
            color: "#000000",
            textAlign: "center",
          }}
        >
          ASSIGN INTERVIEWS
        </Typography>

        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            width: { xs: "100%", sm: "80%" },
            mx: "auto",
            mt: "2rem",
          }}
          onSubmit={formik.handleSubmit}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "start",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ width: { xs: "100%", sm: "34%", lg: "39%" } }}>
              <TextField
                required
                variant="outlined"
                fullWidth
                size="medium"
                id="name"
                label="Candidate Name"
                value={formik.values.name}
                onChange={formik.handleChange("name")}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Box>

            <Box
              sx={{
                width: { xs: "100%", sm: "34%", lg: "39%" },
                mt: { xs: "1rem", sm: "0rem" },
              }}
            >
              <TextField
                required
                variant="outlined"
                fullWidth
                size="medium"
                id="email"
                label="Candidate Email"
                value={formik.values.email}
                onChange={formik.handleChange("email")}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Box>

            <Box
              sx={{
                width: { xs: "100%", sm: "27%", lg: "19%" },
                mt: { xs: "1rem", sm: "0rem" },
              }}
            >
              <TextField
                required
                variant="outlined"
                fullWidth
                size="medium"
                id="interviewer"
                label="Interviewer"
                value={formik.values.interviewer}
                onChange={formik.handleChange("interviewer")}
                select
                SelectProps={{ MenuProps: { disableScrollLock: true } }}
                error={
                  formik.touched.interviewer &&
                  Boolean(formik.errors.interviewer)
                }
                helperText={
                  formik.touched.interviewer && formik.errors.interviewer
                }
              >
                {interviewerLinks.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Box>

          <Box sx={{ width: "50%", mx: "auto", mt: "1rem" }}>
            <Button
              variant="contained"
              fullWidth
              type="submit"
              sx={{
                backgroundColor: "#002E5F",
                mt: "1rem",
                py: "0.75rem",
                textTransform: "none",
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Interviews
