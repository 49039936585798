import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import Checkbox from "@mui/material/Checkbox"
import Button from "@mui/material/Button"
import Job from "../../components/Job"
import Navbar from "../../components/Navbar"
import { jobLevel, jobs, jobType } from "../../utils/data"
import { useQuery } from "@tanstack/react-query"
import { fetcher } from "../../api/fetcher"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { FaLongArrowAltLeft } from "react-icons/fa"

const ViewJobs = () => {
  const navigate = useNavigate()
  // const { isLoading, data, isError, error } = useQuery(["jobs"], () =>
  //   fetcher("jobs")
  // )

  const [selectedRole, setSelectedRole] = useState([])
  const [selectedLevel, setSelectedLevel] = useState([])
  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])

  const handleToggle = (val, previousState) => {
    const currentIndex = previousState.indexOf(val)
    let newCheckedArray = [...previousState]
    if (currentIndex === -1) {
      if (val === "") {
        newCheckedArray = []
      } else {
        const filtered = newCheckedArray.filter(item => item !== "")
        newCheckedArray = [...filtered]
      }
      newCheckedArray.push(val)
    } else {
      newCheckedArray.splice(currentIndex, 1)
    }
    return newCheckedArray.sort((a, b) => a - b)
  }

  const onRoleChange = value => {
    const newRoleState = handleToggle(value, selectedRole)
    setSelectedRole(newRoleState)
  }

  const onLevelChange = value => {
    const newLevelState = handleToggle(value, selectedLevel)
    setSelectedLevel(newLevelState)
  }

  const onChangeHandler = (value, event) => {
    if (event.target.name === "role") {
      onRoleChange(value)
    }

    if (event.target.name === "level") {
      onLevelChange(value)
    }
  }

  const filterDataByRole = data => {
    const order = new Map()
    order.set("frontend developer", [])
    order.set("backend developer", [])
    order.set("fullstack developer", [])
    order.set("android developer", [])
    order.set("ui/ux designer", [])
    order.set("product manager", [])
    order.set("growth manager", [])
    // Entire Loop is O(n)
    data.map((job, index) => {
      const { job_title } = job
      if (job_title.includes("frontend developer")) {
        return order.get("frontend developer").push(job)
      }
      if (job_title.includes("backend developer")) {
        return order.get("backend developer").push(job)
      }
      if (job_title.includes("fullstack developer")) {
        return order.get("fullstack developer").push(job)
      }
      if (job_title.includes("android developer")) {
        return order.get("android developer").push(job)
      }
      if (job_title.includes("ui/ux designer")) {
        return order.get("ui/ux designer").push(job)
      }
      if (job_title.includes("product manager")) {
        return order.get("product manager").push(job)
      }
      if (job_title.includes("growth manager")) {
        return order.get("growth manager").push(job)
      }
    })

    if (selectedRole.length < 1) {
      return data
    } else {
      const dataToDisplay = []

      selectedRole.forEach(item => {
        const selectedJobs = order.get(item)

        dataToDisplay.push(selectedJobs)
      })

      return dataToDisplay.flat()
    }
  }

  const filterDataByLevel = data => {
    const order = new Map()
    order.set("junior", [])
    order.set("intermediate", [])
    order.set("senior", [])
    order.set("lead", [])
    // Entire Loop is O(n)
    data.map((job, index) => {
      const { job_level } = job
      if (job_level.includes("junior")) {
        return order.get("junior").push(job)
      }
      if (job_level.includes("intermediate")) {
        return order.get("intermediate").push(job)
      }
      if (job_level.includes("senior")) {
        return order.get("senior").push(job)
      }
      if (job_level.includes("lead")) {
        return order.get("lead").push(job)
      }
    })

    if (selectedLevel.length < 1) {
      return data
    } else {
      const dataToDisplay = []

      selectedLevel.forEach(item => {
        const selectedJobs = order.get(item)

        dataToDisplay.push(selectedJobs)
      })

      return dataToDisplay.flat()
    }
  }

  const filterData = () => {
    const filteredDataByRole = filterDataByRole(data)
    const filteredDataByLevel = filterDataByLevel(filteredDataByRole)
    setFilteredData(filteredDataByLevel)
  }

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const getData = async () => {
      const jobs = await fetcher("jobs")

      isMounted && setData(jobs.data)
    }

    getData()

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])

  useEffect(() => {
    filterData()
  }, [data, selectedRole, selectedLevel])

  // console.log({ data, filteredData, selectedRole, selectedLevel })

  return (
    <Box sx={{ margin: "0px", padding: "0px", cursor: "default" }}>
      <Box sx={{ backgroundColor: "#FFFFFF" }}>
        <Navbar page="talent" textColor="#1A1F2B" />

        <Box
          sx={{
            padding: {
              xs: "1.5rem 1.5rem",
              sm: "1.5rem 3rem",
              md: "1.5rem 4.5rem",
              lg: "1.5rem 6rem",
            },
          }}
        >
          <Button
            variant="text"
            sx={{ color: "#FF7801", textTransform: "none" }}
            onClick={() => navigate(-1)}
          >
            <FaLongArrowAltLeft style={{ marginRight: "0.5rem" }} /> Go Back
          </Button>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: {
              xs: "3rem 1.5rem",
              sm: "3rem 3rem",
              md: "3rem 4.5rem",
              lg: "3rem 6rem",
            },
          }}
        >
          <Box
            sx={{
              width: "20%",
              mr: "2rem",
              height: "max-content",
              display: { xs: "none", sm: "flex" },
              borderRadius: "8px",
              boxShadow: "0px 4px 5px 1px rgba(193, 193, 193, 0.35)",
              padding: "1.5rem",
              mt: "1.5rem",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{ color: "#3636360", fontWeight: "700" }}
            >
              Filter Jobs
            </Typography>
            <Divider
              sx={{ color: "#EDEDED", width: "100%", margin: "1rem 0rem" }}
            />

            <Box sx={{ margin: "1rem 0rem" }}>
              <Typography
                variant="subtitle2"
                sx={{ color: "#3636360", fontWeight: "700" }}
                gutterBottom
              >
                JOB TYPE
              </Typography>
              <Box>
                {jobType.map((item, index) => (
                  <Box
                    component="span"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "0.25rem 0rem",
                    }}
                    key={index}
                  >
                    <Checkbox
                      size="small"
                      sx={{
                        color: "#292D32",
                        "&.Mui-checked": {
                          color: "#292D32",
                        },
                        padding: "0rem",
                        mr: "0.5rem",
                      }}
                      name="role"
                      id={item.label}
                      value={item.value}
                      checked={selectedRole?.includes(item.value.toLowerCase())}
                      onChange={e =>
                        onChangeHandler(item.value.toLowerCase(), e)
                      }
                    />
                    <Typography variant="body2" sx={{ color: "#252D3E" }}>
                      {item.value}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>

            <Box sx={{ margin: "1rem 0rem" }}>
              <Typography
                variant="subtitle2"
                sx={{ color: "#3636360", fontWeight: "700" }}
                gutterBottom
              >
                JOB LEVEL
              </Typography>
              <Box>
                {jobLevel.map((item, index) => (
                  <Box
                    component="span"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "0.25rem 0rem",
                    }}
                    key={index}
                  >
                    <Checkbox
                      size="small"
                      sx={{
                        color: "#292D32",
                        "&.Mui-checked": {
                          color: "#292D32",
                        },
                        padding: "0rem",
                        mr: "0.5rem",
                      }}
                      name="level"
                      id={item.label}
                      value={item.value}
                      checked={selectedLevel?.includes(
                        item.value.toLowerCase()
                      )}
                      onChange={e =>
                        onChangeHandler(item.value.toLowerCase(), e)
                      }
                    />
                    <Typography variant="body2" sx={{ color: "#252D3E" }}>
                      {item.value}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              mx: "auto",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: { xs: "center", sm: "start" },
              width: { xs: "100%", sm: "77.5%", md: "80%" },
            }}
          >
            {filteredData?.map((job, index) => (
              <Job job={job} key={index} page="all-jobs" />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ViewJobs
